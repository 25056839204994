import React from "react";
import { ServiceListWrapper } from "./styled";

const ServiceList = () => {
  return (
    <ServiceListWrapper>
      <h1>Platform <span>Services</span></h1>
      <div>
        <div>
          <div><img src="/images/platform_services_web.svg" /></div>
          <h3>Web Development</h3>
          <p>
            Develop responsive web application with SPA and SSR architectures for smooth operations
          </p>
        </div>
        <div>
          <div><img src="/images/platform_services_mobile.svg" /></div>
          <h3>Mobile Development</h3>
          <p>
            Fast and Performant mobile applications with cross platform and native frameworks
          </p>
        </div>
        <div>
          <div><img src="/images/platform_services_api.svg" /></div>
          <h3>API & Backend</h3>
          <p>
            Backend development with latest tech stacks and data platform development with Data Lake and Data Mining Architectures
          </p>
        </div>
        <div>
          <div><img src="/images/platform_services_iot.svg" /></div>
          <h3>Internet of Things</h3>
          <p>
            Secure and Available services with IoT and Intelligent Edge Architectures
          </p>
        </div>
      </div>
    </ServiceListWrapper>
  )
}

export default ServiceList;